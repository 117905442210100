import React from 'react';
import {Link} from "gatsby";

const Navbar = ({ navbarRef, bgTransparent }) => {

  return (
    <nav className={`navbar navbar-expand-lg navbar-light style-5 ${bgTransparent ? 'bg-transparent':''}`} ref={navbarRef}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/v1/logos/logo-color.png" alt="" />
        </Link>
      </div>
    </nav>
  )
}

export default Navbar