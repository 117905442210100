import React, {useEffect, useRef} from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import AbsoluteContainer from 'components/Nav/AbsoluteContainer';
import Navbar from 'components/Nav/SaasNav';
import NotFound from 'components/404';
import {graphql} from "gatsby";

const Page404 = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current, true);
  }, [navbarRef]);

  return (
    <MainLayout>
      <AbsoluteContainer>
        <Navbar navbarRef={navbarRef}/>
      </AbsoluteContainer>
      <main className="erorr-404-page style-5">
        <NotFound/>
      </main>
    </MainLayout>
  )
}

export const Head = ({data}) => {
  const {title} = data.site.siteMetadata;
  return (
    <>
      <title>{title} - Not Found</title>
    </>
  )
}

export default Page404;

export const query = graphql`
query MyQuery {
  site {
    siteMetadata {
      title
    }
  }
}
`;